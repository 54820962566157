<template>
    <div>
        <div   class=" pb-9 diagonal pt-0 pt-md-5">
            <div class="py-4 mt--4 mb--3">
                <h3 class="mb-0 text-white pt-4">SEO manažment</h3>
                <h2 class=" mb--5">{{contenttype}}</h2>
                <div class="text-right system-options w-100 d-block" style="">
                </div>
            </div>
        </div>
        <div class="container-fluid mt--7">
            <div class="row">
                <div class="col">
                    <div class="card shadow">
                        <div class="table-responsive">
                            <a-table
                                    :columns="columns"
                                    :rowKey="record => record.id"
                                    :dataSource="data"
                                    :loading="loading"
                                    class="table-flush">


                                <template slot="editable" slot-scope="text, record">
                                   <div class="editable-cell">
                                        <div v-if="editable === 'seotitle' + record.id" class="editable-cell-input-wrapper">
                                            <a-input :value="text" @change="(e) => handleChange(e,record.id,'seotitle')" @pressEnter="check(record.id,'seotitle')" /><a-icon
                                                type="check"
                                                class="editable-cell-icon-check"
                                                @click="check(record.id,'seotitle')"
                                        />
                                        </div>
                                        <div v-else class="editable-cell-text-wrapper">
                                            {{ text || ' ' }}
                                            <a-icon type="edit" class="editable-cell-icon" @click="edit(record.id,'seotitle')" />
                                        </div>
                                    </div>
                                </template>
                                <template slot="editableUrl" slot-scope="text, record">
                                     <div class="editable-cell">
                                        <div v-if="editable === 'url' + record.id" class="editable-cell-input-wrapper">
                                            <a-input :value="text" @change="(e) => handleChange(e,record.id,'url')" @pressEnter="check(record.id,'url')" /><a-icon
                                                type="check"
                                                class="editable-cell-icon-check"
                                                @click="check(record.id,'url')"
                                        />
                                        </div>
                                        <div v-else class="editable-cell-text-wrapper">
                                            {{ text || ' ' }}
                                            <a-icon type="edit" class="editable-cell-icon" @click="edit(record.id,'url')" />
                                        </div>
                                    </div>
                                </template>
                                <template slot="editableDescription" slot-scope="text, record">
                                    <div class="editable-cell">
                                        <div v-if="editable === 'seodescription' + record.id" class="editable-cell-input-wrapper">
                                            <a-input :value="text" @change="(e) => handleChange(e,record.id,'seodescription')" @pressEnter="check(record.id,'seodescription')" /><a-icon
                                                type="check"
                                                class="editable-cell-icon-check"
                                                @click="check(record.id,'seodescription')"
                                        />
                                        </div>
                                        <div v-else class="editable-cell-text-wrapper">
                                            {{ text || ' ' }}
                                            <a-icon type="edit" class="editable-cell-icon" @click="edit(record.id,'seodescription')" />
                                        </div>
                                    </div>
                                </template>
                                <template slot="editableKeywords" slot-scope="text, record">
                                    <div class="editable-cell">
                                        <div v-if="editable === 'seokeywords' + record.id" class="editable-cell-input-wrapper">
                                            <a-input :value="text" @change="(e) => handleChange(e,record.id,'seokeywords')" @pressEnter="check(record.id,'seokeywords')" /><a-icon
                                                type="check"
                                                class="editable-cell-icon-check"
                                                @click="check(record.id,'seokeywords')"
                                        />
                                        </div>
                                        <div v-else class="editable-cell-text-wrapper">
                                            {{ text || ' ' }}
                                            <a-icon type="edit" class="editable-cell-icon" @click="edit(record.id,'seokeywords')" />
                                        </div>
                                    </div>
                                </template>


                                <template slot="date" slot-scope="detailed">
                                    {{formatDateN(detailed)}}
                                </template>
                                <template slot="trans" slot-scope="translations,record">
                                    <div class="trans" :key="lang + record.entity_id" v-for="(trans,lang) in translations">
                                        <router-link :to="'/edit-content/' + trans.entity_id" v-if="trans.lang" class="translation-button m-1" :href="'/edit-content/' + trans.entity_id">
                                            <a-button type="secondary"  :title="trans.value"  class="add-button edit-button"  icon="fal fa-check">{{lang}}</a-button>
                                        </router-link>
                                        <a-button v-else type="primary"   class="translation-button m-1" @click="handleSubmit(record.entity_id,lang)" icon="far fa-plus">{{lang}}</a-button>
                                    </div>
                                </template>
                                <template slot="name" slot-scope="user">
                                    <router-link :to="'/edit-content/' + user.id" class="btn text-default btn-icon btn-secondary btn-sm" :href="'/edit-content/' + user.entity_id"><span class="btn-inner--icon" style="margin-right:5px"><i class="fas fa-pencil-alt"></i></span>Upraviť</router-link>
                                </template>
                            </a-table>
                        </div>
                    </div>
                    <div class="quick-links" v-if="contenttypes">
                        <div class="card-footer pb-5" style="display:inline-block;width:100%;">
                            <label style="float:left;display:inline;margin-right:20px;" class="text-muted">Rýchle odkazy preklady: </label>
                            <span :key="carr.id" v-for="carr in contenttypes" >
                           <router-link :to="'/seo/' + carr.slug"  class="m-1 float-left"  :href="'/seo/' + carr.slug">
                                <a-button type="outline-primary" :class="'extramuted text-muted' + (carr.slug === slug  ? ' selected' : '')" >{{carr.title}}</a-button>
                        </router-link>
</span>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import {dataService} from "../_services";
    import moment from 'moment'

    import FormItem from 'ant-design-vue'
    import Vue from "vue"
    Vue.use(FormItem);

    const columns = [
        {
            title: 'Názov',
            dataIndex: 'title',
        },
        {
            title: 'URL',
            dataIndex: 'url',
            scopedSlots: { customRender: 'editableUrl' },
        },
        {
            title: 'Seo title',
            dataIndex: 'seotitle',
            scopedSlots: { customRender: 'editable' },
        },
        {
            title: 'Seo description',
            dataIndex: 'seodescription',
            scopedSlots: { customRender: 'editableDescription'}
        },
        {
            title: 'Seo keywords',
            dataIndex: 'seokeywords',
            scopedSlots: { customRender: 'editableKeywords'}
        },
        {
            align: 'right',
            scopedSlots: {customRender: 'name'},
        }
    ];

    var locale = {
        "lang": {
            "placeholder": "Vybrať dátum",
            "rangePlaceholder": ["Termín od", "do"],
            "today": "Dnes",
            "now": "Teraz",
            "backToToday": "Späť na dnes",
            "ok": "Ok",
            "clear": "Vymazať",
            "month": "Mesiac",
            "year": "Rok",
            "timeSelect": "Vybrať čas",
            "dateSelect": "Vybrať dátum",
            "monthSelect": "Vybrať mesiac",
            "yearSelect": "Vybrať rok",
            "decadeSelect": "Vybrať dekádu",
            "yearFormat": "YYYY",
            "dateFormat": "M/D/YYYY",
            "dayFormat": "D",
            "dateTimeFormat": "M/D/YYYY HH:mm:ss",
            "monthFormat": "MMMM",
            "monthBeforeYear": true,
            "previousMonth": "Predchádzajúci mesiac (PageUp)",
            "nextMonth": "Nasledujúci mesiac (PageDown)",
            "previousYear": "Minulý rok (Control + left)",
            "nextYear": "Nasledujúci rok (Control + right)",
            "previousDecade": "Minulá dekáda",
            "nextDecade": "Nasledujúca dekáda",
            "previousCentury": "Minulé storočie",
            "nextCentury": "Nasledujúce storoťie"
        },
        "timePickerLocale": {
            "placeholder": "Vybrať čas"
        },
        "dateFormat": "DD.MM.YYYY",
        "dateTimeFormat": "DD.MM.YYYY HH:mm:ss",
        "weekFormat": "YYYY-wo",
        "monthFormat": "YYYY-MM"
    }

    export default {
        name: "translations",
        props: ["slug"],
        components: {},
        data() {
            return {
                data: [],
                campaignModal: false,
                loading: false,
                editable:false,
                value:"",
                locale,
                types:[{"value":1,"label": "Objednávka"},{"value":2,"label": "Produkt"}],
                contenttype: "",
                contenttypes:[],
                campaign: {'title':'','image':''},
                colorsto: JSON.parse(localStorage.colors),
                columns,
            };
        },
        mounted() {
            this.fetch();
            dataService.axiosFetch("seotypes").then(results => {

                let pa = this;
                results.forEach(obj => {

                    if(obj.slug === pa.slug)
                        pa.contenttype = obj.title;
                });

                this.contenttypes = results;
            });

        },
        methods: {
            handleChange(e,id,dataIndex) {
                const value = e.target.value;
                this.value = value;
                const dataSource = [...this.data];
                const target = dataSource.find(item => item.id === id);
                if (target) {
                    target[dataIndex] = value;
                    this.dataSource = dataSource;


                }
            },
            check(id,dataIndex) {
                this.editable = false;

                dataService.axiosPost("seoupdate", {"value" : this.value,"nid":id,"field":dataIndex});


            },
            edit(e,dataIndex) {

                this.editable = dataIndex + e;
            },
            onCellChange(key, dataIndex, value) {
                const dataSource = [...this.dataSource];
                const target = dataSource.find(item => item.key === key);
                if (target) {
                    target[dataIndex] = value;
                    this.dataSource = dataSource;
                }
            },
            addCampaign()
            {
                dataService.axiosPost("adddocument", this.campaign).then(() => {
                    this.fetch();
                });
                //  this.campaignModal = false;
            },
            onChildChange(value) {
                this.campaign.image = JSON.stringify(value);
            },
            openCampaignModal() {
                this.campaignModal = true;
            },
            confirm(e) {
                dataService.axiosDelete(this.$options.name, e).then(results => {
                    if (results) {
                        this.data.splice(this.data.findIndex(x => parseInt(x.id) === parseInt(e)), 1);
                    }
                });
            },
            cancel() {
            },
            formatDateN: function(dt){

                return moment(dt,'YYYY-MM-DD').format('D.M.YYYY')


            },
            fetch() {
                this.loading = true;
                dataService.axiosFetch('seo',this.slug).then(results => {
                    this.data = results;
                    this.loading = false;
                });
            }

        },
    };
</script>

<style>
    .image-editor .image-uploader,
    .image-editor .image-property
    {width:49%!important;}
    ul.ant-pagination {
        margin-top: 0;
        margin-bottom: 1em;
    }
    .translation-button
    {float:left;}

    .ant-table-pagination.ant-pagination {
        margin-bottom: 40px;
        margin-right: 20px;
    }

    .ant-pagination-item {
        border: 0;
    }

    .ant-table {
        border-bottom: 1px solid rgba(0, 0, 0, 0.05);
        margin-bottom: 20px;
    }

    .ant-pagination-item a, .ant-pagination-prev a, .ant-pagination-next a, .ant-pagination-jump-prev a, .ant-pagination-jump-next a {
        -webkit-box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
        box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
        border: 0;
    }

    .ant-pagination-item a, .ant-pagination-prev a, .ant-pagination-next a {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        padding: 0;
        margin: 0 3px;
        border-radius: 50% !important;
        width: 36px;
        height: 36px;
        font-size: 0.875rem;
    }


    .ant-pagination-item.ant-pagination-item-active a {
        z-index: 1;
        color: #fff;
        background-color: #5e72e4;
        border-color: #5e72e4;
    }

    .ant-pagination-item-link.ant-pagination-disabled a {
        color: #8898aa;
        pointer-events: none;
        cursor: auto;
        background-color: #fff;
        border-color: #dee2e6;
    }

</style>
